import React, { useState, useEffect } from 'react';
import styled from '../../styled';
const GlitchWrap = styled.span `
  position: relative;
  color: var(--mint);
  /* text-shadow: 0 0px 30px #350344; */
  animation: fadeIn 1s linear;
  @keyframes fadeIn {
    0% {
      color: var(--ghostY);
      /* text-shadow: 0 0 0 transparent; */
    }
    20% {
      color: var(--ghostY);
      /* text-shadow: 0 0 0 transparent; */
    }
    100% {
      color: var(--mint);
      /* text-shadow: 0 0px 30px #350344; */
    }
  }
`;
const GlitchBase = styled.span ``;
const GlitchLine = styled.span `
  position: absolute;
  top: -1.5px;
  &:nth-child(1) {
    animation: clip 3000ms -300ms linear infinite, glitch1 1000ms -228ms linear infinite;
  }
  &:nth-child(2) {
    animation: clip 3000ms -600ms linear infinite, glitch2 1000ms -927ms linear infinite;
  }
  &:nth-child(3) {
    animation: clip 3000ms -900ms linear infinite, glitch3 1000ms -496ms linear infinite;
  }
  &:nth-child(4) {
    animation: clip 3000ms -1200ms linear infinite, glitch4 1000ms -660ms linear infinite;
  }
  &:nth-child(5) {
    animation: clip 3000ms -11000ms linear infinite, glitch5 1000ms -399ms linear infinite;
  }
  &:nth-child(6) {
    animation: clip 3000ms -1800ms linear infinite, glitch6 1000ms -657ms linear infinite;
  }
  &:nth-child(7) {
    animation: clip 3000ms -2100ms linear infinite, glitch7 1000ms -619ms linear infinite;
  }
  &:nth-child(8) {
    animation: clip 3000ms -2400ms linear infinite, glitch8 1000ms -380ms linear infinite;
  }
  &:nth-child(9) {
    animation: clip 3000ms -2700ms linear infinite, glitch9 1000ms -863ms linear infinite;
  }
  &:nth-child(10) {
    animation: clip 3000ms -3000ms linear infinite, glitch10 1000ms -745ms linear infinite;
  }

  @keyframes glitch1 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(-3px);
      color: var(--good);
    }
    90% {
      transform: translateX(0px);
      color: var(--bad);
    }
    95% {
      transform: translateX(3px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch2 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(5px);
      color: var(--good);
    }
    90% {
      transform: translateX(5px);
      color: var(--bad);
    }
    95% {
      transform: translateX(3px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch3 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(-2px);
      color: var(--good);
    }
    90% {
      transform: translateX(-4px);
      color: var(--bad);
    }
    95% {
      transform: translateX(4px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch4 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(3px);
      color: var(--good);
    }
    90% {
      transform: translateX(1px);
      color: var(--bad);
    }
    95% {
      transform: translateX(-2px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch5 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(-2px);
      color: var(--good);
    }
    90% {
      transform: translateX(-3px);
      color: var(--bad);
    }
    95% {
      transform: translateX(-4px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch6 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(-4px);
      color: var(--good);
    }
    90% {
      transform: translateX(-4px);
      color: var(--bad);
    }
    95% {
      transform: translateX(1px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch7 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(2px);
      color: var(--good);
    }
    90% {
      transform: translateX(-3px);
      color: var(--bad);
    }
    95% {
      transform: translateX(4px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch8 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(0px);
      color: var(--good);
    }
    90% {
      transform: translateX(-2px);
      color: var(--bad);
    }
    95% {
      transform: translateX(2px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes glitch9 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
  }

  @keyframes glitch10 {
    0% {
      transform: translateX(0);
    }
    80% {
      transform: translateX(0);
      color: inherit;
    }
    85% {
      transform: translateX(-1px);
      color: var(--good);
    }
    90% {
      transform: translateX(-3px);
      color: var(--bad);
    }
    95% {
      transform: translateX(-1px);
      color: inherit;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes clip {
    0% {
      clip-path: polygon(0 100%, 100% 100%, 100% 120%, 0 120%);
    }
    100% {
      clip-path: polygon(0 -20%, 100% -20%, 100% 0%, 0 0);
    }
  }
`;
const Glitch = ({ text, isActive }) => {
    return (React.createElement(GlitchWrap, null,
        isActive && [...Array(10).keys()].map((i) => React.createElement(GlitchLine, { key: i }, text)),
        React.createElement(GlitchBase, { style: { opacity: isActive ? 0 : 1 } }, text)));
};
export default Glitch;
const useRandomFlip = () => {
    const [isFlipped, setIsFlipped] = useState(false);
    useEffect(() => {
        const flipBoolean = () => {
            setIsFlipped(true);
            const activeDuration = Math.floor(Math.random() * (2400 - 400 + 1) + 400);
            setTimeout(() => {
                setIsFlipped(false);
            }, activeDuration);
            const nextFlipDelay = Math.floor(Math.random() * (32000 - 8000 + 1) + 8000);
            setTimeout(() => {
                flipBoolean();
            }, nextFlipDelay);
        };
        flipBoolean();
    }, []);
    return isFlipped;
};
export const GlitchGlyph = ({ children }) => {
    const isActive = useRandomFlip();
    return (React.createElement(GlitchWrap, null,
        isActive &&
            [...Array(10).keys()].map((i) => (React.createElement(GlitchLine, { style: { top: '0px' }, key: i }, children))),
        React.createElement(GlitchBase, { style: { opacity: isActive ? 0 : 1 } }, children)));
};
