import React, { useEffect } from 'react';
import { Hero, HeroText, HeroContainer, HeroLoop, HeroMainText } from './styled';
const isChrome = () => typeof navigator !== 'undefined' && navigator.userAgent.includes('Chrome');
export default () => {
    useEffect(() => {
        if (isChrome()) {
            //@ts-ignore
            document.querySelector('video').addEventListener('timeupdate', function (e) {
                if (this.currentTime > 7.77) {
                    this.currentTime = 0.0;
                }
            }, false);
        }
    }, []);
    return (React.createElement(Hero, null,
        React.createElement(HeroContainer, null,
            React.createElement(HeroMainText, null,
                React.createElement(HeroText, { text: 'modern web3' }),
                React.createElement(HeroText, { text: 'for desktop' })),
            React.createElement(HeroLoop, null,
                React.createElement("video", { preload: 'true', autoPlay: true, loop: true, muted: true, playsInline: true },
                    React.createElement("source", { src: 'https://frame.nyc3.cdn.digitaloceanspaces.com/home/assets/3-22-final.mov', type: 'video/mp4; codecs="hvc1"' }),
                    React.createElement("source", { src: 'https://frame.nyc3.cdn.digitaloceanspaces.com/home/assets/3-22-final.webm', type: 'video/webm' }))))));
};
// <HeroLeft>
//         {/* <HeroText>
//             <Glitch text={'web3'} isActive />
//           </HeroText> */}
//         {/* <VHS data-text='harness web3 magic'>{''}</VHS>
//           </HeroText>
//           <HeroText>
//             <VHS data-text='harness web3 magic'>{'harness web3 magic'}</VHS>
//           </HeroText>
//           <HeroText>
//             <VHS data-text='harness web3 magic'>{'harness web3 magic'}</VHS>
//           </HeroText> */}
//         {/* <HeroText>
//             <VHS data-text='frame'>{'frame'}</VHS>
//           </HeroText> */}
//         {/* <HeroText>
//             <VHS data-text='await'>{'await'}</VHS>
//           </HeroText> */}
//         {/* <HeroText>{'wonders'}</HeroText>
//           <HeroText>{'await'}</HeroText>
//           <HeroText>{'await'}</HeroText> */}
//         <div>
//           {
//             'Discover the new standard for modern web3 on desktop and tap in with a platform nativly built for web3 experiences.'
//           }
//         </div>
//         {/* <div>
//             {
//               'Say goodbye to clunky browser extensions and hello to a faster, safer, and more intuitive way of exploring the decentralized web.'
//             }
//           </div> */}
//       </HeroLeft>
//       <HeroRight></HeroRight>
