import React from 'react';
import styled from '../../styled';
import { main, social } from '../Top/links.js';
const footerCutOff = '600px';
const FooterContainer = styled.footer `
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  color: var(--mint);
  background: var(--ghostX);
  padding: 64px 0px;
  @media (max-width: ${footerCutOff}) {
    padding: 0px;
    background: var(--ghostY);
  }
`;
const FooterSection = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  max-width: var(--target-width);
  padding: 64px 0px;
  box-sizing: border-box;
  flex-direction: column;
  padding: 0px;
  margin: 0;
  @media (max-width: ${footerCutOff}) {
    flex-direction: column;
    padding: 0px;
    margin: 0;
  }
`;
const FooterColumn = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 3);
  img {
    height: 20px;
    width: auto;
    border-bottom: 1px solid black;
  }
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  img {
    border-bottom: 1px solid black;
  }
  @media (max-width: ${footerCutOff}) {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    img {
      border-bottom: 1px solid black;
    }
  }
`;
const FooterLink = styled.a `
  color: var(--mint);
  text-decoration: none;
  cursor: pointer;
  margin: 8px;
  font-weight: 600;
  width: 100%;
  margin: 0px;
  padding: 20px;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  @media (max-width: ${footerCutOff}) {
    width: 100%;
    margin: 0px;
    padding: 20px;
    border-bottom: 1px solid black;
    box-sizing: border-box;
  }

  &:hover {
    color: var(--mintOver);
  }

  :last-child {
    border-bottom: none;
  }
`;
const SocialMediaIcons = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
`;
const SocialMediaIcon = styled.div `
  color: #fff;
  width: 80px;
  height: 80px;

  &:hover {
    color: #ccc;
  }
`;
const FooterLogo = styled.div `
  img {
    height: 20px;
    width: auto;
    padding-bottom: 16px;
  }
`;
const CopyrightNotice = styled.div `
  padding: 64px 0px;
  font-weight: 600;
  font-size: 10px;
  color: var(--mintOver);
`;
export const Community = styled.div `
  display: flex;
  padding: 32px;
`;
export const CommunityLink = styled.div `
  padding: 2px 16px 0px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: var(--mintOver);
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: var(--mint);
  }
`;
const NewsletterForm = styled.form `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NewsletterInput = styled.input `
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  border: none;
  background-color: var(--ghostB);
  color: #fff;
  outline: none;
  border-radius: 8px;
`;
const NewsletterButton = styled.button `
  background-color: var(--ghostB);
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 8px;

  &:hover {
    background-color: var(--ghostC);
  }
`;
const links = [
    { text: 'Docs', url: 'https://docs.frame.sh' },
    { text: 'Blog', url: 'https://blog.frame.sh' },
    { text: 'Careers', url: 'https://careers.frame.sh' },
    { text: 'Brand Assets', url: 'https://frame.sh' },
    { text: 'Privacy Policy', url: 'https://frame.sh' },
    { text: 'Terms of Service', url: 'https://frame.sh' }
];
// Footer component
const Footer = () => {
    return (React.createElement(FooterContainer, null,
        React.createElement(FooterLogo, null,
            React.createElement("img", { src: 'https://frame.nyc3.cdn.digitaloceanspaces.com/home/framemint.webp' })),
        React.createElement(Community, null, social.map((link) => {
            return (React.createElement(CommunityLink, { onClick: () => {
                    window.open(link.url);
                } }, link.svg));
        })),
        React.createElement(FooterSection, null,
            React.createElement(FooterColumn, null, main.map((link, linkIndex) => (React.createElement(FooterLink, { key: linkIndex, href: link.url }, link.text))))),
        React.createElement(CopyrightNotice, null,
            "\u00A9 ",
            new Date().getFullYear(),
            " Frame Labs, Inc.")));
};
export default Footer;
