import styled from '../../../styled';
export const DownloadWrap = styled.div `
  padding: 32px 0px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
`;
export const DownloadButtonOverlay = styled.div `
  position: absolute;
  top: 48px;
  right: 6px;
  bottom: 44px;
  left: 6px;
  z-index: 200;
  pointer-events: none;
  // transition: var(--standardFast);
  background: linear-gradient(90deg, var(--overlayA), var(--overlayB));
  // background: rgba(355, 0, 0, 0.3);
  border-radius: 24px;
  opacity: 1;
  display: none;
`;
export const DownloadButton = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  width: 320px;
  height: 40px;
  padding: 8px;
`;
export const DownloadTags = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 700;
  color: var(--mintOver);
  height: 8px;
  margin-top: 8px;
`;
export const DownloadTag = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--mintOver);
  padding: 0px 8px;
  height: 100%;
  cursor: ${(props) => (props.onClick ? `pointer` : '')};
  &:last-child {
    border-right: 0px solid var(--mint);
  }
  &:hover {
    color: ${(props) => (props.onClick ? `var(--mint)` : '')};
  }
`;
