import React from 'react';
import { Top, Logo, Menu, MenuItem, TopBackdrop, MenuIcons, MenuIcon, MenuLaunch, TopExpanded, TopExpandedBody, TopExpandedItem } from './styled';
import { bars } from '../svg';
import { main, social } from './links.js';
export default () => {
    const [expanded, setExpanded] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Top, null,
            React.createElement(Logo, { href: 'https://frame.sh' },
                React.createElement("img", { src: 'https://frame.nyc3.cdn.digitaloceanspaces.com/home/framemint.webp' })),
            React.createElement(Menu, null, main.map((link) => {
                return React.createElement(MenuItem, { href: link.url }, link.text);
            })),
            React.createElement(MenuIcons, null, social.map((link) => {
                return (React.createElement(MenuIcon, { href: link.url, target: '_blank' }, link.svg));
            })),
            React.createElement(MenuLaunch, { onClick: () => setExpanded(!expanded) }, bars)),
        React.createElement(TopBackdrop, null),
        expanded && (React.createElement(TopExpanded, null,
            React.createElement(TopExpandedBody, null,
                main.map((link) => {
                    return React.createElement(TopExpandedItem, { href: link.url }, link.text);
                }),
                social.map((link) => {
                    return React.createElement(TopExpandedItem, { href: link.url }, link.text);
                }))))));
};
