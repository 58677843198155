import React, { useState, useEffect } from 'react';
import styled from '../../styled';
import { DownloadWrap, DownloadButtonOverlay, DownloadButton, DownloadTags, DownloadTag } from './styled';
import { Cluster, ClusterValue, ClusterAdapt } from '../Cluster';
let os = () => {
    if (typeof window === 'undefined')
        return '';
    let userAgent = window.navigator.userAgent;
    let platform = window.navigator.platform;
    if (['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(platform) !== -1)
        return 'mac';
    if (['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(platform) !== -1)
        return 'windows';
    if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1)
        return 'ios';
    if (/Android/.test(userAgent))
        return 'android';
    if (/Linux/.test(platform))
        return 'linux';
    return '';
};
const downLoadCutOff = '800px';
const HeroCluster = styled(Cluster) `
  position: 'relative';
  z-index: 4;
  background: var(--ghostA);
  box-shadow: 0px 8px 16px 0px var(--ghostY);
  max-width: 680px;
`;
const HeroClusterValue = styled(ClusterValue) `
  color: var(--mintOver);
  background: var(--ghostB);
  border-bottom: 2px solid var(--ghostA);
  box-shadow: 0px 2px 4px 0px var(--ghostY);
  * {
    pointer-events: none;
  }
  &:hover {
    color: var(--mint);
    transform: translateY(-1px);
    background: var(--ghostC);
    border-bottom: 2px solid var(--ghostA);
    box-shadow: 0px 4px 9px 0px var(--ghostY);
    position: relative;
    z-index: 300000;
    transform: translateY(-2px);
  }
  &:active {
    color: var(--mint);
    transform: translateY(-1px);
    background: var(--ghostC);
    border-bottom: 2px solid var(--ghostA);
    box-shadow: 0px 1px 2px 0px var(--ghostY);
    position: relative;
    z-index: 300000;
    transform: translateY(1px);
  }
`;
const createDownloadLink = (os, releases) => { };
export default () => {
    const [releases, setReleases] = useState({
        version: '',
        download: {
            macintel: '',
            macarm: '',
            linuxdeb: '',
            linuxapp: '',
            windows: '',
            unknown: 'https://github.com/floating/frame/releases/latest'
        }
    });
    useEffect(() => {
        const releasesUpdate = {
            version: '',
            download: {
                macintel: '',
                macarm: '',
                linuxdeb: '',
                linuxapp: '',
                windows: '',
                unknown: 'https://github.com/floating/frame/releases/latest'
            }
        };
        fetch('https://api.github.com/repos/floating/frame/releases/latest')
            .then((res) => res.json())
            .then((release) => {
            releasesUpdate.version = release.tag_name;
            release.assets.forEach((asset) => {
                const url = asset.browser_download_url;
                if (url.endsWith('arm64.dmg')) {
                    releasesUpdate.download.macarm = url;
                }
                else if (url.endsWith('.dmg') && !url.endsWith('arm64.dmg')) {
                    releasesUpdate.download.macintel = url;
                }
                else if (url.endsWith('.exe')) {
                    releasesUpdate.download.windows = url;
                }
                else if (url.endsWith('.deb') && !url.endsWith('arm64.deb')) {
                    releasesUpdate.download.linuxdeb = url;
                }
                else if (url.endsWith('.AppImage')) {
                    releasesUpdate.download.linuxapp = url;
                }
            });
            setReleases(releasesUpdate);
        })
            .catch((err) => console.log('Error fetching releases...', err));
    }, []);
    const osType = os();
    const { macarm, macintel, linuxdeb, linuxapp, windows, unknown } = releases.download;
    return (React.createElement(DownloadWrap, null,
        React.createElement(DownloadButtonOverlay, null),
        React.createElement(HeroCluster, null, osType === 'mac' ? (React.createElement(ClusterAdapt, null,
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(macarm || unknown, '_self') },
                React.createElement(DownloadButton, null, "Download for Apple Silicon")),
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(macintel || unknown, '_self') },
                React.createElement(DownloadButton, null, "Download for Intel")))) : osType === 'linux' ? (React.createElement(ClusterAdapt, null,
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(linuxdeb || unknown, '_self') },
                React.createElement(DownloadButton, null, "Download for Linux (Debian)")),
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(linuxapp || unknown, '_self') },
                React.createElement(DownloadButton, null, "Download for Linux (AppImage)")))) : osType === 'windows' ? (React.createElement(ClusterAdapt, null,
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(windows || unknown, '_self') },
                React.createElement(DownloadButton, null, "Download for Windows")))) : (React.createElement(ClusterAdapt, null,
            React.createElement(HeroClusterValue, { onClick: () => typeof window !== 'undefined' && window.open(unknown, '_blank') },
                React.createElement(DownloadButton, null, "Download Frame"))))),
        React.createElement(DownloadTags, null,
            React.createElement(DownloadTag, null, releases.version),
            React.createElement(DownloadTag, { onClick: () => typeof window !== 'undefined' &&
                    window.open('https://github.com/floating/frame/releases/latest', '_blank') }, 'VIEW ALL BUILDS'))));
};
