import styled from '../../../styled';
import React, { useState, useEffect } from 'react';
import Glitch from '../../Glitch';
// const levels = ['960px', '768px', '480px', '375px']
// https://github.com/floating/frame/releases/latest/*.arm64.dmg
const heroCutOff = '800px';
const heroCutOff2 = '500px';
export const Hero = styled.div `
  position: relative;
  height: 480px;
  margin: 0 auto;
  width: var(--target-width);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -16px;
  transform: translate3d(0, 0, 0);

  @media (max-width: ${heroCutOff}) {
    height: 600px;
  }
`;
export const HeroContainer = styled.div `
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  transform: translate3d(0, 0, 0);
  @media (max-width: ${heroCutOff}) {
    flex-direction: column-reverse;
    padding-top: 48px;
  }
`;
export const HeroMainText = styled.div `
  padding-left: 12px;
  padding-top: 4px;
  padding-right: 12px;
  transform: translate3d(0, 0, 0);
  @media (max-width: ${heroCutOff}) {
    padding: 32px;
  }
`;
// export const HeroLight = styled.div`
//   position: absolute;
//   top: 85px;
//   right: 135px;
//   height: 380px;
//   width: 380px;
//   border-radius: 250px;
//   z-index: 3;
//   pointer-events: none;
//   opacity: 0.06;
//   display: none;
//   background: radial-gradient(circle at center, #c300ff 0, transparent 65%);
//   * {
//     pointer-events: none;
//   }
// `
export const HeroLoop = styled.div `
  overflow: hidden;
  margin-left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  pointer-events: none;
  * {
    pointer-events: none;
  }
  video {
    width: 200px;
    position: relative;
    z-index: 1;
    /* margin: -100px -80px -80px -80px; */
    transform: translate3d(0, 0, 0);
  }

  @media (max-width: ${heroCutOff}) {
    margin-left: 0px;
    margin-bottom: 32px;
  }
`;
export const HeroTextWrap = styled.div `
  font-size: ${(props) => (props.size ? props.size : '36px')};
  font-weight: 500;
  text-align: right;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 16px;
  letter-spacing: 8px;
  font-family: 'VCR';
  animation: slide 2s ease-out;
  @keyframes slide {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  &:last-child {
    padding-bottom: 0px;
  }

  @media (max-width: ${heroCutOff2}) {
    font-size: ${(props) => (props.size ? props.size : '28px')};
  }
`;
export const VHS = styled.span `
  text-transform: uppercase;
  position: relative;
  mix-blend-mode: screen;
  animation: glitchy 10s infinite alternate;

  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0px;
    left: 0;
    mix-blend-mode: screen;
    animation: glitchy 10s infinite alternate;
  }

  &::before {
    text-shadow: 0 0 0 var(--bad);
  }

  &::after {
    text-shadow: 0 0 0 var(--good);
    animation-delay: 2s;
  }

  @keyframes glitchy {
    0% {
      transform: translate(0rem);
    }
    50% {
      transform: translate(0.3rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
`;
export const HeroText = ({ text, size }) => {
    const letters = [text];
    const [activeLetters, setIsActive] = useState([]);
    useEffect(() => {
        const setGlitchLetters = (initial) => {
            const onLetters = [];
            letters.forEach((l, i) => {
                const random = Math.random();
                const threshold = 4 / letters.length;
                const glitch = random < threshold;
                if (glitch) {
                    onLetters.push(i);
                }
            });
            setTimeout(() => {
                setIsActive(onLetters);
                setTimeout(() => {
                    setIsActive([]);
                }, Math.random() * 500 + 250);
            }, initial ? 0 : Math.random() * 3000);
        };
        const interval = setInterval(() => {
            setGlitchLetters();
        }, 8000);
        setGlitchLetters(true);
        return () => {
            clearInterval(interval);
        };
    }, []);
    return (React.createElement(HeroTextWrap, { size: size ? size : '' },
        React.createElement(Glitch, { text: text, isActive: activeLetters.includes(0) })));
};
// export const HeroDownload = styled.div`
//   background: var(--ghostA);
//   margin-top: 32px;
//   height: 64px;
//   width: 110px;
//   border-radius: 16px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;
//   font-weight: 400;
//   cursor: pointer;
//   box-shadow: 0 2px 4px var(--ghostY);
//   * {
//     pointer-events: none;
//   }
//   &:hover {
//     color: var(--good);
//     background: var(--ghostB);
//   }
// `
// font-family: __calibre_c79c8f, __calibre_Fallback_c79c8f;
// cursor: default;
// text-size-adjust: 100%;
// --ease-in: cubic-bezier(0.55,0.085,0.68,0.53);
// --ease-out: cubic-bezier(0.25,0.46,0.45,0.94);
// --ease-in-out: cubic-bezier(0.455,0.03,0.515,0.955);
// font-synthesis: none;
// -webkit-font-smoothing: antialiased;
// font-weight: 600;
// line-height: 50px;
// color: rgb(255, 255, 255);
// box-sizing: border-box;
// margin: 0px;
// padding: 0px;
// font-size: inherit;
// outline: none;
// display: inline-block;
// -webkit-text-fill-color: transparent;
// background: linear-gradient(89.29deg, rgb(179, 174, 245) 2%, rgb(215, 203, 231) 20%, rgb(229, 200, 200) 31%, rgb(234, 168, 121) 70%) text;
// 95.2deg,
// rgba(192, 229, 246, 1) 0%,
// rgba(173, 252, 234, 1) 25%,
// rgba(192, 229, 246, 1) 50%,
