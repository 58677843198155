import React from 'react';
import styled, { createGlobalStyle } from '../styled';
import { Provider } from 'react-wrap-balancer';
import Top from './Top';
import Hero from './Hero';
import Download from './Download';
import { ContentBreak, Omnichain, Transactions, Hardware, Extension, Privacy, Community, Security, WatchOnly, SystemWide, OpenSource } from './Content';
import Ribbon from './Ribbon';
import Footer from './Footer';
const height = '64px';
const mainCutoff = '720px';
const mainCutoff2 = '400px';
const GlobalStyle = createGlobalStyle `

  body {
    min-width: 375px;
    overflow-x: hidden;
    * {
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }


    
  :root {
    --target-width: 960px;
   }

  /* @media (max-width: 1280px) {
    :root {
      --target-width: 960px;
    }
  } */

  @media (max-width: 1080px) {
    :root {
      --target-width: 768px;
    }
  }

  @media (max-width: 860px) {
    :root {
      --target-width: 640px;
    }
  }

  @media (max-width: 720px) {
    :root {
      --target-width: 460px;
    }
  }

  @media (max-width: 500px) {
    :root {
      --target-width: 360px;
    }
  }
`;
const Overlay = styled.div `
  position: fixed;
  inset: 0px;
  background: linear-gradient(-45deg, var(--overlayA) 0%, var(--overlayB) 90%);
  z-index: 999999990;
  pointer-events: none !important;
  opacity: 1;
  // mix-blend-mode: darken;
`;
const NoiseOverlay = styled.div `
  position: fixed;
  inset: 0px;
  z-index: 9999999991;
  background: url('https://frame.nyc3.cdn.digitaloceanspaces.com/home%2Fnoise.gif');
  opacity: 0;
  transform: translate3d(0, 0, 1px);
  animation: tune-in 32s linear infinite;
  pointer-events: none !important;
  mix-blend-mode: screen;

  @keyframes tune-in {
    0% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    48% {
      opacity: 0.05;
    }
    52% {
      opacity: 0.05;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
const ContentItem = styled.div `
  position: relative;
  padding-bottom: 16px;
`;
const SubHero = styled.div `
  --bg-size: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-size: 24px;
  font-weight: 800;
  padding: 96px 0px;
  background: linear-gradient(90deg, var(--mint), var(--mint), #ffd2cd, var(--mint)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 24s linear infinite;
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
  @media (max-width: ${mainCutoff}) {
    width: 340px;
    padding: 64px 0px;
    margin: 0 auto;
  }

  @media (max-width: ${mainCutoff2}) {
    width: 300px;
    font-size: 20px;
    margin: 0 auto;
  }
`;
const Demo = styled.div `
  min-width: 900px;
  min-height: 357.61px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    max-height: 600px;
    object-fit: contain;
  }
`;
export const Summary = styled.div `
  width: var(--target-width);
  max-width: var(--target-width);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 32px;
  box-sizing: border-box;
  margin: 0 auto;
`;
export const SummaryText = styled.div `
  width: 100%;
  text-align: center;
  line-height: 25px;
  box-sizing: border-box;
  color: var(--mintOver);
  --bg-size: 300%;
  background: linear-gradient(90deg, var(--mint), var(--mint), #ffeae6, #edd3ff, var(--mint)) 0 0 /
    var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 18px;
  font-weight: 800;

  animation: move-bg 24s linear infinite;
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
  @media (max-width: ${mainCutoff}) {
    width: 300px;
  }
`;
const FadeIn = styled.div `
  background: #0026369d;
  position: absolute;
  top: -140px;
  right: 240px;
  left: 240px;
  height: 300px;
  border-radius: 160px;
  filter: blur(80px);
  opacity: 0.2;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
`;
const ContentContainer = styled.div `
  max-width: var(--target-width);
  width: var(--target-width);
  margin: 0 auto;
`;
const CompileContent = () => {
    const content = [
        Omnichain,
        Transactions,
        Hardware,
        Privacy,
        OpenSource,
        Security,
        SystemWide,
        Extension,
        Community,
        WatchOnly
    ];
    const colors = [
        'var(--mintOver)',
        'rgb(147, 224, 255)',
        'rgb(255, 255, 228)',
        'rgb(255, 219, 254)',
        'var(--good)',
        'var(--pending)',
        'var(--moon)',
        'var(--bad)',
        'var(--good)',
        'var(--pending)'
    ];
    const groups = [2, 5, 7];
    return (React.createElement(ContentContainer, null, content.map((Module, index) => {
        const reverse = index % 2 === 0;
        const group = groups.includes(index);
        return (React.createElement(React.Fragment, null,
            React.createElement(ContentItem, { key: index },
                React.createElement(Module, { reverse: reverse, color: 'var(--mintOver)' })),
            group && React.createElement(ContentBreak, null)));
    })));
};
class App extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(GlobalStyle, null),
            React.createElement(NoiseOverlay, null),
            React.createElement(Overlay, null),
            React.createElement(Top, null),
            React.createElement(Hero, null),
            React.createElement(Provider, null,
                React.createElement(Summary, null,
                    React.createElement(SummaryText, null,
                        React.createElement("div", null, 'Transcend browser extensions and unlock system-wide web3'))),
                React.createElement(Download, null),
                React.createElement("div", { style: {
                        overflowX: 'scroll',
                        overflowY: 'hidden',
                        padding: '48px 32px 0px 32px'
                    } },
                    React.createElement(FadeIn, null),
                    React.createElement(Demo, null,
                        React.createElement("img", { src: 'https://frame.nyc3.cdn.digitaloceanspaces.com/home/assets/frame06screens4.webp' }))),
                React.createElement(SubHero, null,
                    React.createElement("div", null, 'Discover the new standard for web3 on desktop')),
                React.createElement(Ribbon, null),
                CompileContent(),
                React.createElement(ContentItem, null,
                    React.createElement(Summary, null,
                        React.createElement(SummaryText, null,
                            React.createElement("div", { style: { marginTop: '60px' } }, `Push the boundaries of web3`))),
                    React.createElement(Download, null)),
                React.createElement(Footer, null))));
    }
}
export default App;
