import React from 'react';
import styled from '../../styled';
import items from './items';
const Marquee = styled.div `
  padding-bottom: 64px;
`;
const Scroller = styled.div `
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;
const ScrollRow = styled.div `
  display: inline-block;
  animation: marquee 60s linear infinite;
  min-width: 100%;
`;
const ScrollContent = styled.div `
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px 0px;
`;
const ScrollItem = styled.div `
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  color: var(--mintOver) !important;

  a,
  a:visited {
    display: block;
    position: relative;
    color: var(--mintOver);
    fill: var(--mintOver);
    height: 100%;
    width: 100%;
    text-decoration: none;
    font-weight: 800;
    font-size: 26px;
    font-style: italic;
    text-transform: uppercase;

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;
const ScrollerReverse = styled.div `
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  @keyframes marqueeReverse {
    0% {
      transform: translate(-100%, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;
const ScrollRowReverse = styled.div `
  display: inline-block;
  animation: marqueeReverse 80s linear infinite;
  min-width: 100%;
  height: 100px;
`;
const [topItems, botItems] = items();
const Ribbon = () => {
    return (React.createElement(Marquee, null,
        React.createElement(Scroller, null,
            React.createElement(ScrollRow, null,
                React.createElement(ScrollContent, null, topItems.map((item, index) => {
                    return (React.createElement(ScrollItem, { key: index },
                        React.createElement("a", { href: item.link }, item.svg || item.alt)));
                }))),
            React.createElement(ScrollRow, null,
                React.createElement(ScrollContent, null, topItems.map((item, index) => {
                    return (React.createElement(ScrollItem, { key: index },
                        React.createElement("a", { href: item.link }, item.svg || item.alt)));
                })))),
        React.createElement(ScrollerReverse, null,
            React.createElement(ScrollRowReverse, null,
                React.createElement(ScrollContent, null, botItems.map((item, index) => {
                    return (React.createElement(ScrollItem, { key: index },
                        React.createElement("a", { href: item.link }, item.svg || item.alt)));
                }))),
            React.createElement(ScrollRowReverse, null,
                React.createElement(ScrollContent, null, botItems.map((item, index) => {
                    return (React.createElement(ScrollItem, { key: index },
                        React.createElement("a", { href: item.link }, item.svg || item.alt)));
                }))))));
};
export default Ribbon;
